import { PlayerApi } from 'Services/player';
import {
  type TGetCommonNotificationResponse,
  type TMarkNotificationAsReadArgs,
} from './notifications.types';

const API_BASE_URL = '/notifications/api/v1/notifications';

export const CommonNotificationsApi = PlayerApi.injectEndpoints({
  endpoints: (builder) => ({
    getCommonNotifications: builder.query<TGetCommonNotificationResponse, string>({
      query: (playerId) => `${API_BASE_URL}/${playerId}`,
    }),
    commonMarkAsRead: builder.mutation<any, TMarkNotificationAsReadArgs>({
      query: ({ notificationId }) => ({
        url: `${API_BASE_URL}/markNotificationAsRead`,
        method: 'POST',
        body: { notificationId },
      }),
    }),
    commonMarkAllAsRead: builder.mutation<any, void>({
      query: () => ({
        url: `${API_BASE_URL}/markAllNotificationsAsRead`,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useCommonMarkAsReadMutation,
  useCommonMarkAllAsReadMutation,
  useGetCommonNotificationsQuery,
  useLazyGetCommonNotificationsQuery,
} = CommonNotificationsApi;
