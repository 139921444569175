import { type Translated } from '@casumo/directus-api';
import { type IntlShape } from '@formatjs/intl';
import { type Toasts, type ToastsTranslations, type DirectusFiles } from 'Src/directus.types';
import { getImgixURL, translate } from 'Utils';
import {
  type TDirectusCollections,
  type TCmsMarketConfigContextType,
  type Messages,
  type TGqlResult,
  type TLocalizedQuery,
} from 'Hooks';
import { type TToastPayload, type TToast } from 'Store';
import { gatekeeper } from 'Src/gatekeeper';
import { GET_TOAST_QUERY, type TLockType, type TValuableType, VALUABLE_TYPE } from 'Services';
import { VALUABLE_STATE_TOAST_NAME } from './Notifications.constants';
import { GetAchievementsQuery } from './NotificationsApi';

type TFlattenedToasts = Toasts & ToastsTranslations;
type TFetchCmsHooks<T extends keyof TDirectusCollections> = {
  localizedQuery: TLocalizedQuery<TGqlResult<T>>;
  marketConfig: TCmsMarketConfigContextType;
};

export const fetchToastCmsData = async (
  toastName: string,
  { localizedQuery, marketConfig }: TFetchCmsHooks<'market_config_by_id'>
): Promise<Toasts> => {
  const { localeCode } = gatekeeper.localisation;
  const marketConfigId = marketConfig?.id ?? '';

  const data = await localizedQuery({
    query: GET_TOAST_QUERY,
    translationsNodeNames: ['toasts'],
    variables: {
      marketConfigId,
      toastName,
    },
  });

  // @ts-expect-error next-line - caused by directus sdk - TODO: fix it if you know how
  if (!data?.market_config_by_id?.toasts?.[0]?.toasts_id) {
    throw new Error(`Failed to get ${toastName} from toasts collection`);
  }
  // @ts-expect-error next-line - caused by directus sdk - TODO: fix it if you know how
  const { translations, ...root } = data?.market_config_by_id?.toasts?.[0]
    .toasts_id as unknown as Translated<Toasts>;

  return { ...root, ...translate({ translations }, localeCode, true) };
};

// we are using id from event not from CMS, hence we omit here
export const transformToast = (toast: TFlattenedToasts) => {
  const thumbnail_image = toast.thumbnail_image as DirectusFiles;

  return {
    name: toast.name,
    kind: toast.kind,
    priority: toast.priority,
    message: toast.message,
    title: toast.title,
    autoclose_duration: toast.autoclose_duration,
    primary_button_text: toast.primary_button_text ?? undefined,
    secondary_button_text: toast.secondary_button_text ?? undefined,
    thumbnail_image: getImgixURL(thumbnail_image?.filename_disk ?? '') ?? undefined,
  } as Omit<TToast, 'id'>;
};

export const enrichContentInterpolation = async (
  contentInterpolation: Record<string, string> = {},
  data: any,
  localizedQuery: TLocalizedQuery<TGqlResult<'achievements'>>
) => {
  if (data?.achievementType) {
    const achievementTranslation = await localizedQuery({
      query: GetAchievementsQuery,
      variables: {
        achievementName: data?.achievementType,
      },
      translationsNodeNames: ['achievements'],
    });
    return {
      ...contentInterpolation,
      achievementName: achievementTranslation?.achievements?.[0]?.translations?.[0]?.title,
      image: getImgixURL(
        achievementTranslation?.achievements?.[0]?.translations?.[0]?.image?.filename_disk
      ),
    };
  }

  return contentInterpolation;
};

// todo: rework to use util fn in useIntl
export const interpolate = (
  toast: Omit<TToast, 'id'>,
  templateParameters: any,
  // this below argument is what we get from useIntl hook
  { intl }: { intl: IntlShape<string> }
): Omit<TToast, 'id'> => {
  try {
    const { message, title, ...rest } = toast;
    const interpolatedMessages: Messages = {};

    const messages: Messages = {
      message,
      title,
    } as Record<string, string>;

    Object.entries(messages).forEach(([id, defaultMessage]) => {
      if (defaultMessage) {
        intl.messages[id] = defaultMessage;
        interpolatedMessages[id] = intl.formatMessage({ id, defaultMessage }, templateParameters);
      }
    });

    return { ...interpolatedMessages, ...rest };
  } catch (error) {
    console.warn('Failed to interpolate Toast');
    return toast;
  }
};

export const getToastName = (cmsSlug: string, itemType?: TValuableType | TLockType): string => {
  if (cmsSlug === 'item-activation-success') {
    if (
      (
        [
          VALUABLE_TYPE.BONUS_MONEY,
          VALUABLE_TYPE.REAL_MONEY,
          VALUABLE_TYPE.DEPOSIT_BONUS,
        ] as unknown as TValuableType
      ).includes(itemType as TValuableType)
    ) {
      return VALUABLE_STATE_TOAST_NAME.USE_SUCCESS;
    }

    if (
      (
        [
          VALUABLE_TYPE.SIMPLE_FREE_SPINS,
          VALUABLE_TYPE.SIMPLE_BONUS_SPINS,
          VALUABLE_TYPE.KAMBI_FREE_BET,
          VALUABLE_TYPE.LIVE_CASINO_FREE_BET,
          VALUABLE_TYPE.KAMBI_OFFER,
          VALUABLE_TYPE.KAMBI_PROFIT_BOOST,
          VALUABLE_TYPE.SPORTS_FREE_BET,
          VALUABLE_TYPE.CASHBACK,
          VALUABLE_TYPE.SPORTS_DEPOSIT_BONUS,
        ] as unknown as TValuableType
      ).includes(itemType as TValuableType)
    ) {
      return VALUABLE_STATE_TOAST_NAME.READY_TO_USE;
    }

    if (itemType === ('wageringLock' as unknown as TLockType)) {
      return VALUABLE_STATE_TOAST_NAME.READY_TO_WAGER;
    }

    return VALUABLE_STATE_TOAST_NAME.USE_SUCCESS;
  }

  if (cmsSlug === 'item-activation-failure') {
    return VALUABLE_STATE_TOAST_NAME.USE_FAILURE;
  }

  if (cmsSlug === 'upcoming-item-expiration') {
    return VALUABLE_STATE_TOAST_NAME.ABOUT_TO_EXPIRE;
  }

  return cmsSlug;
};

export const getShouldIgnoreToast = (toast: TToastPayload) => {
  const { page } = gatekeeper.navigation.getCurrentRoute();
  return page === 'PLAY' && toast.name === 'leaderboard-participant-rankup';
};
