export const GetAchievementsQuery = `
  query Achievements($achievementName: String) {
    achievements(filter: { name: { _eq: $achievementName } }) {
        translations {
            title
            subtitle
            subtitle_two
            image {
                id
                filename_disk
            }
            id
            achievements_id {
                id
            }
            terms
            content
            secondary_button_label
            primary_button_label
        }
    }
  }
`;
