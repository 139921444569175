import { type TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { configureStore, type Middleware } from '@reduxjs/toolkit';
import { PlayerApi } from 'Services';
import { ToastSlice } from './toastSlice';
import { UserSlice } from './userSlice';
import PopUpsSlice from './popUpSlice';
import { FastTrackRealTimeIntegrationApi } from 'Services/fasttrackRealtimeIntegration/fasttrackRealtimeIntegration.api';

const middleware = (getDefaultMiddleware: () => any[]): Middleware[] =>
  getDefaultMiddleware()
    .concat(PlayerApi.middleware)
    .concat(FastTrackRealTimeIntegrationApi.middleware);

const reducer = {
  [PlayerApi.reducerPath]: PlayerApi.reducer,
  [FastTrackRealTimeIntegrationApi.reducerPath]: FastTrackRealTimeIntegrationApi.reducer,
  [UserSlice.name]: UserSlice.reducer,
  [ToastSlice.name]: ToastSlice.reducer,
  [PopUpsSlice.name]: PopUpsSlice.reducer,
};

const config = {
  reducer,
  middleware,
};

export const store = configureStore(config);

export type TAppDispatch = typeof store.dispatch;

export type TRootState = ReturnType<typeof store.getState>;

export const useAppDispatch: () => TAppDispatch = useDispatch;

export const useAppSelector: TypedUseSelectorHook<TRootState> = useSelector;
