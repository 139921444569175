import { useEffect } from 'react';
import {
  fetchToastCmsData,
  interpolate as interpolateToast,
  transformToast,
} from '../components/Notifications/Notifications.utils';
import { selectPlayerId, type TToast } from '../store';
import { useCmsMarketConfig } from './useCmsMarket';
import { useToast } from './useToast';
import { useIntl } from './useIntl';
import { useDirectusLocalizedQuery } from './useDirectus';
import { useSelector } from 'react-redux';
import { useGetCommonNotificationsQuery } from 'Services/notifications/commonNotificationsApi';
import { gatekeeper } from 'Src/gatekeeper';

type TPayload = {
  category: string;
  createdTime: number;
  id: string;
  kind: string;
  parameters: { badgeId: string };
  type: 'ITEM_EARNED';
};

export const useItemEarnedToast = () => {
  const localizedQuery = useDirectusLocalizedQuery<'market_config_by_id'>();
  const marketConfig = useCmsMarketConfig();
  const intl = useIntl();
  const { addItem, toasts } = useToast();
  const playerId = useSelector(selectPlayerId);
  const { data: commonPendingNotifications, refetch } = useGetCommonNotificationsQuery(
    playerId ?? '',
    {
      skip: !playerId,
    }
  );

  useEffect(() => {
    // scope of this useEffect is to subscribe to messageBus for item-created event and fire a toast
    const ids: string[] = [];
    const fireItemEarnedToast = async (payload: TPayload) => {
      const toastName = 'item-earned';

      const optionalPayload = payload?.parameters?.badgeId && {
        valuableId: payload?.parameters?.badgeId,
      };

      const id = payload?.id;
      ids.push(id);

      const isToastExisting = toasts?.some(
        (item) => item?.optionalPayload?.valuableId === payload?.parameters?.badgeId
      );

      if (isToastExisting || !payload?.parameters?.badgeId) {
        return;
      }

      fetchToastCmsData(toastName, { localizedQuery, marketConfig })
        .then(transformToast)
        .then((toast) => interpolateToast(toast, {}, { intl }))
        .then((toast) => {
          addItem({ ...toast, id, optionalPayload } as TToast);
        })
        .catch(console.error);
    };

    // @ts-expect-error next-line TODO: fix typings from gatekeeper
    const unsubscribe = gatekeeper.messageBus.subscribe('external', (payload: TPayload) => {
      if (payload.kind === 'item-created') {
        fireItemEarnedToast(payload);
      }
    });

    commonPendingNotifications?.forEach((commonPendingNotification) => {
      if (commonPendingNotification.type !== 'ITEM_EARNED') return;
      // @ts-expect-error next-line TODO: fix typings from gatekeeper
      fireItemEarnedToast(commonPendingNotification);
      refetch();
    });

    // based on product decision we do not render primary button to use valuable on toast

    // gatekeeper.messageBus.subscribe('toast:reaction', (payload) => {
    //   if (ids.includes(payload.id)) {
    //     if (payload.kind === 'primary') {
    //       setValuableState({
    //         state: 'Used',
    //         badgeId: payload.id,
    //       });

    //       navigate('CASH_DEPOSIT');
    //       dispatch(removeToast(payload.id));
    //     }
    //   }
    // });
    return function cleanup() {
      unsubscribe();
    };
  }, [commonPendingNotifications, addItem, intl, localizedQuery, marketConfig]);
};
