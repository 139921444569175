export const GTM_BASE = Object.freeze({
  TOPIC: 'analytics',
  KIND: 'gtm',
});

export const GTM_FEATURES = Object.freeze({
  TOASTS: 'toasts',
  LOS_BANNER_WIDGET: 'los-banner-widget',
  HERO_LIS: 'hero-lis',
  HERO_LOS: 'hero-los',
  VALUABLE_DETAILS_MODAL: 'retention.valuable_details_modal',
  PLAYER_DETAILS_MODAL: 'retention.player_details_modal',
  ACHIEVEMENTS_CAROUSEL: 'retention.achievements_carousel',
  VALUABLES_LIST: 'retention.valuables_list',
  ACHIEVEMENTS_MODAL: 'retention.achievements_modal',
  HALL_OF_FAME_PAGE: 'retention.hall_of_fame_page',
  HALL_OF_FAME_GROUP: 'retention.hall_of_fame_group',
  HALL_OF_FAME_LIST: 'retention.hall_of_fame_list',
  HALL_OF_FAME_LEADERBOARD: 'retention.hall_of_fame_leaderboard',
  MARKETING_WIDGET: 'retention.email_marketing_widget',
  QUICK_EMAIL_MARKETING_WIDGET: 'retention.email_casino_widget',
  REEl_RACES_HERO: 'retention.rr_page_happening_now_swimlane',
  REEl_RACES_HERO_CARD: 'retention.rr_page_happening_now_swimlane_card',
  REEl_RACES_UPCOMING: 'retention.rr_page_upcoming_swimlane',
  REEl_RACES_UPCOMING_CARD: 'retention.rr_page_upcoming_swimlane_card',
  REEL_RACES_IN_GAME_WIDGET: 'retention.reel_races_in_game_widget',
  RIDABODO_IN_GAME_WIDGET: 'retention.ridabodo_in_game_widget',
  RIDABODO_INFO_DRAWER: 'retention.ridabodo_info_drawer',
  RIDABODO_INVITE_DRAWER: 'retention.ridabodo_invite_drawer',
  RIDABODO_PROMOTION_PAGE: 'retention.ridabodo_promotion_page',
  RIDABODO_TOAST: 'retention.ridabodo_toast',
});

export const GTM_ACTIONS = Object.freeze({
  SHOWN: 'shown',
  CLICK: 'click',
  PLAY_NOW: 'play_now',
  SHOW_TERMS: 'show_terms',
  CARD_CLICK: 'card_click',
  OPTIN_CLICK: 'optin_click',
  PRIZES_CLICK: 'prizes_click',
  PLAY_CLICK: 'play_click',
  LEADERBOARD_CLICK: 'leaderboard_click',
  DEPOSIT_CLICK: 'deposit_click',
  GAME_LAUNCH: 'game_launch',
  CLOSE: 'close',
});

export type TProps = {
  title?: string;
  content?: string;
  image?: string;
  className?: string;
  emptyPromoTitle?: string;
  emptyPromoContent?: string;
  emptyPromoImage?: string;
  buttonLabel?: string;
};
